import { defineVuepalAdapter } from '#vuepal/types'
import { useCurrentLanguage } from '#imports'

export default defineVuepalAdapter(() => {
  return {
    getAdminMenu() {
      return useGraphqlQuery('adminToolbar', null, {
        graphqlCaching: {
          client: true,
        },
      }).then((v) => v.data)
    },
    getLocalTasks(path: string) {
      return useGraphqlQuery(
        'localTasks',
        { path },
        {
          graphqlCaching: {
            client: true,
          },
        },
      ).then((v) => {
        if (v.data.route && 'localTasks' in v.data.route) {
          return v.data.route.localTasks
        }
        return []
      })
    },
    // TODO remove this method as soon as vuepal is updated
    getCurrentLanguage() {
      return useCurrentLanguage()
    },
  }
})
